const linkCatalog = document.querySelectorAll(".catalog__item");

function activeCatalog() {
  linkCatalog.forEach((l) => l.classList.remove("catalog-active"));
  this.classList.add("catalog-active");
}

linkCatalog.forEach((l) => l.addEventListener("click", activeCatalog));

function app() {
  const buttons = document.querySelectorAll(".catalog__item");
  const cards = document.querySelectorAll(".products__item");

  function filter(category, items) {
    items.forEach((item) => {
      const isItemFiltered = !item.classList.contains(category);
      const isShowAll = category.toLowerCase() === "all";
      if (isItemFiltered && !isShowAll) {
        item.classList.add("anime");
      } else {
        item.classList.remove("hide");
        item.classList.remove("anime");
      }
    });
  }

  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      const currentCategory = button.dataset.filter;
      filter(currentCategory, cards);
    });
  });

  cards.forEach((card) => {
    card.ontransitionend = function () {
      if (card.classList.contains("anime")) {
        card.classList.add("hide");
      }
    };
  });
}

app();
