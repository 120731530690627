import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
Swiper.use([Navigation, Pagination]);
Swiper.use([Autoplay]);

let swiperReviews = new Swiper(".reviews__slider", {
  spaceBetween: 24,
  loop: true,
  grabCursor: true,
  slidesPerView: 3,
  autoplay: true,
  autoplay: {
    delay: 2000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  lazy: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 1,
    },
    770: {
      slidesPerView: 2,
    },
    968: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
});

let swiperAdvantages = new Swiper(".advantages__slider", {
  spaceBetween: 24,
  loop: true,
  grabCursor: true,
  slidesPerView: 3,
  autoplay: true,
  autoplay: {
    delay: 2000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  lazy: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 1,
    },
    770: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
  },
});
