$(document).ready(function () {
  $(".contact-form__input").on("keyup", function () {
    let empty = false;

    $(".contact-form__input").each(function () {
      empty = $(this).val().length == 0;
    });

    if (empty) $(".btn--submit").attr("disabled", "disabled");
    else $(".btn--submit").attr("disabled", false);
  });
});
