$(".mobile div").on("click", () => {
  $(".mobile div").toggleClass("active");
  $(".mobile nav").toggleClass("open");
  $(".mobile nav ul").toggleClass("show");
  $(".page__body").toggleClass("overflow");
});

$(".mobile .mobile__link").on("click", () => {
  $(".mobile div").removeClass("active");
  $(".mobile nav").removeClass("open");
  $(".mobile nav ul").removeClass("show");
  $(".page__body").removeClass("overflow");
});

for (let a = 1; a <= $(".mobile ul li").length; a++) {
  $(".mobile ul li:nth-child(" + a + ")").css(
    "animation-delay",
    "." + (a + 1) + "s"
  );
}

let bg = document.querySelector(".hero__right--picture");
window.addEventListener("mousemove", function (e) {
  let x = e.clientX / window.innerWidth;
  let y = e.clientY / window.innerHeight;
  bg.style.transform = "translate(-" + x * 50 + "px, -" + y * 50 + "px)";
});
